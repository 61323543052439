import { useEffect } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
export default function RedirectPage (){

 const {state} =  useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.replace(state.redirectUri);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '50vh', 
        textAlign: 'center' 
      }}
    >
      <Avatar
        sx={{
          width: 50,
          height: 50,
          marginBottom: 2,
        }}
      >
        <img
          src="/glowing-logo.png"
          alt="Glowing Logo"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Avatar>
      <Typography variant="h6">
        You will be redirected to ChatGPT in 5 seconds...
      </Typography>
    </Box>
  );
};

