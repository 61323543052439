import { Typography } from "@mui/material";
import { useRouteError } from "react-router-dom";

export default function ErrorPage(){
  const error = useRouteError();
  console.error(error);

  return (
    <center>
      <div id="error-page">
        <h1>Oops!</h1>
        <Typography>Sorry, an unexpected error has occurred.</Typography>
        <Typography>
          <i>{error?.statusText || error?.message}</i>
        </Typography>
      </div>
    </center>
  );
};


