import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import Copyright from "@/components/Copyright";
import GlowingAppBar from "@/components/Appbar";

function PricingPage() {
  return (
    <div>
      <GlowingAppBar />
      <Box sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <CardContent>
              <Typography
                variant="h4"
                component="div"
                sx={{ marginBottom: 2, textAlign: "center" }}
              >
                <b>Welcome to Glowing!</b>
              </Typography>
              <Typography
                variant="body1"
                sx={{ lineHeight: 1.5, textAlign: "justify" }}
              >
                We're thrilled to have you here. Let's get you started:
                <br />
                <br />
              </Typography>
              <Box sx={{ marginLeft: 2 }}>
                <Typography variant="body1">
                  - <b>If you're already part of our Glowing family</b>, please
                  enter the phone number you've registered with us in the{" "}
                  <b>billing section</b>. We'll take care of the rest.
                </Typography>
                <br />
                <Typography variant="body1">
                  - <b>If you're new here</b> (welcome, by the way!), please
                  enter the phone number you'd like to use with Glowing in the{" "}
                  <b>billing section</b>. We're excited to have you onboard!
                </Typography>
              </Box>

              <Typography
                variant="body1"
                sx={{ marginTop: 2, fontWeight: "bold", textAlign: "center" }}
              >
                Let's get glowing!
              </Typography>
            </CardContent>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Card
              sx={{
                minWidth: 275,
                boxShadow: 2,
                padding: 3,
                borderRadius: 2,
                backgroundColor: "background.paper",
                color: "text.primary",
              }}
            >
              <CardContent>
                <stripe-pricing-table
                  pricing-table-id="prctbl_1NSD2QIfGWMvqeDWy5iy8vze"
                  publishable-key="pk_live_51NAnDdIfGWMvqeDWoorZ62CAaVrwdi9ifUROwnGRqs5TI0iVLKyknTWhgTIMaswlP9OPDDzXYFEqNNKNel6UlGbH00pxSvYu3A"
                ></stripe-pricing-table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <footer>
        <Copyright />
      </footer>
    </div>
  );
}

export default PricingPage;
