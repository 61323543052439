import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from "@/assets/glowing-logo.png";
import Copyright from "@/components/Copyright";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

export default function LandingPage() {
  const navigate = useNavigate();

  const handleCardClick = (text) => {
    alert(text);
  };

  function navigateToPricingPage() {
    navigate("/pricing");
  }

  function navigateToLogin() {
    navigate("/login");
  }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          m: 1,
          height: "100vh",
          width: "100%",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{ width: "100px", marginBottom: "20px" }}
        />
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          pb={4}
          style={{ marginBottom: "20px" }}
        >
          Glowing ChatGPT Plugin
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Card
            sx={{
              width: 345,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              transition: "0.3s",
              borderRadius: "3px",
              boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
              cursor: "pointer",
              "&:hover": {
                boxShadow: `0 16px 40px rgba(0,0,0,0.4)`,
              },
            }}
            onClick={() => {
              Sentry.captureMessage(`Subscribe Invoked`);
              navigateToPricingPage();
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                sx={{ wordWrap: "break-word", textAlign: "center" }}
              >
                I want to subscribe
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              width: 345,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "0.3s",
              borderRadius: "3px",
              boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
              cursor: "pointer",
              "&:hover": {
                boxShadow: `0 16px 40px rgba(0,0,0,0.4)`,
              },
            }}
            onClick={() => navigateToLogin()}
          >
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                sx={{ wordWrap: "break-word", textAlign: "center" }}
              >
                I already have a subscription
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <footer>
        <Copyright />
      </footer>
    </div>
  );
}
