import { AppBar, ButtonBase, Toolbar, Typography } from "@mui/material";
import logo from '@/assets/glowing-logo.png'
import { useNavigate } from "react-router-dom";

export default function GlowingAppBar () {
  const navigate = useNavigate();
  const handleBackButtonClick = () => {
    navigate("/");
  };
  return (
    <AppBar position="relative">
      <Toolbar sx={{ bgcolor: "white" }}>
       <ButtonBase  onClick={handleBackButtonClick}>
        <img
          src={logo}
          alt="glowing logo"
          style={{
            width: "40px",
            height: "auto",
          }}
        ></img>
        </ButtonBase>
        <Typography variant="h6" color="black" paddingX={"4px"} noWrap>
          Glowing
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
