import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import { useState, useEffect } from "react";
import Copyright from "@/components/Copyright";
import InfoIcon from "@mui/icons-material/Info";
import glowingLogo from "@/assets/glowing-logo.png";
import { Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

const API_BASE_URL = import.meta.env.VITE_PLUGIN_ENDPOINT || "http://localhost:3000";
const PLUGIN_AUTH = "plugin_auth";

const makeApiCall = async (method, url, data) => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 30000,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const sendOTP = async (phoneNumber, url, name = "", type) => {
  const method = type === PLUGIN_AUTH ? "POST" : "PUT";
  return makeApiCall(method, url, { phone_number: phoneNumber, name });
};

const verifyOTP = async (phoneNumber, otp, url) => {
  return makeApiCall("POST", url, { phone_number: phoneNumber, otp });
};

function useQuery() {
  return new URLSearchParams(window.location.search);
}


export default function Login() {
  const [isOTP, setIsOTP] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [otp, setOTP] = useState("");
  const [error, setError] = useState(null);
  const [redirectTo, setRedirectTo] = useState("");
  const [type, setType] = useState("");
  const [showResendButton, setShowResendButton] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const query = useQuery();
    setRedirectTo(decodeURIComponent(query.get("return_to")));
    setType(query.get("type"));
  }, []);

  const handleSendOTP = async (phoneNumber, url, name, type) => {
    try {
      await sendOTP(phoneNumber, url, name, type);
      setIsOTP(true);
      setError(null);
      Sentry.captureMessage(`OTP sent to ${phoneNumber} - ${name} - ${url} - ${type}`)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.response
      ) {
        setError(error.response.data.response);
      } else {
        setError("Failed send. Please try again."); // Fallback error message
      }
      Sentry.captureMessage(`Failed to send OTP ${phoneNumber} - ${name} - ${url} - ${type} - ${error}`)
    }
  };

  const handleAuthRedirect = async (phoneNumber, otp) => {
    try {
      // Fetch redirect_uri from backend
      const auth_response = await axios.get(
        `${API_BASE_URL}${redirectTo}&phone_number=${phoneNumber}&otp=${otp}`
      );

      console.log("auth response", auth_response.data);

      if (auth_response.data && auth_response.data.redirect_uri) {
        console.log("redirecting to", auth_response.data.redirect_uri);
        navigate('/redirect',{state:{redirectUri:auth_response.data.redirect_uri}});
      }
    } catch (error) {
      console.error(error);
      throw error; // Throw the error so it can be caught and handled by handleVerifyOTP
    }
  };

  const handleVerifyOTP = async (phoneNumber, otp, otpUrl, type) => {
    try {
      const response = await verifyOTP(phoneNumber, otp, otpUrl);

      if (response.status === 200 || response.status === 201) {
        if (type === PLUGIN_AUTH) {
          // Authentication success. Redirect to ChatGPT Plugin
          await handleAuthRedirect(phoneNumber, otp);
        } else {
          //Navigate to Stripe Customer Portal
          window.location.href = response.data.url;
        }
        setIsOTP(false);
        setError(null);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.error_description) {
          setError(error.response.data.error_description);
        }

        if (error.response.data.response) {
          setError(error.response.data.response);
        }
        // Display the error message from the server response
      } else {
        setError("An error occurred. Please try again."); // Fallback error message
      }
    }
  };

  const handleResendOTP = async (phoneNumber, name, type) => {
    const url = `${API_BASE_URL}/v1/user`;
     try {
       await sendOTP(phoneNumber, url, name, type);
       setError(null);
       Sentry.captureMessage(`Resent OTP to ${phoneNumber} - ${name} - ${type}`);
       setShowResendButton(false)
     } catch (error) {
       if (
         error.response &&
         error.response.data &&
         error.response.data.response
       ) {
         setError(error.response.data.response);
       } else {
        Sentry.captureMessage(`Failed Resending OTP. ${phoneNumber}`);
         setError("Failed Sending OTP. Please try after sometime.");
       }
     }
   };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isOTP) {
      const url = `${API_BASE_URL}/v1/user`;
      await handleSendOTP(phoneNumber, url, name, type);
    } else {
      const otpUrl =
        type === PLUGIN_AUTH
          ? `${API_BASE_URL}/v1/otp`
          : `${API_BASE_URL}/v1/stripe_portal`;
      await handleVerifyOTP(phoneNumber, otp, otpUrl, type);
    }
  };


  useEffect(() => {
    if (isOTP) {
      const timer = setTimeout(() => {
        setShowResendButton(true);
      }, 15000);
      return () => clearTimeout(timer);
    }
  }, [isOTP]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          bgcolor: "background.default",
        }}
      >
        <Card sx={{ minWidth: 400, mt: 1, borderRadius: 3, height: 'fit-content' }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 3,
              }}
            >
              <Avatar
                sx={{ m: 2, bgcolor: "secondary.main", width: 56, height: 56 }}
              >
                <img
                  src={glowingLogo}
                  alt="Glowing Logo"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Avatar>

              <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                {type === PLUGIN_AUTH ? "Register" : "Sign In"}
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1, width: "100%" }}
              >
                {!isOTP ? (
                  <>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="phoneNumber"
                      label="Phone Number"
                      name="phoneNumber"
                      placeholder="Phone number with country code"
                      value={phoneNumber}
                      inputProps={{
                        type: "text",
                        inputMode: "numeric",
                        pattern: "d*",
                        min: 0,
                      }}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setPhoneNumber(e.target.value);
                        }
                      }}
                      autoFocus
                    />

                    {type === PLUGIN_AUTH && (
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="Name"
                        value={name}
                        inputProps={{
                          type: "text",
                          inputMode: "text",
                          pattern: "d*",
                          min: 0,
                        }}
                        onChange={(e) => setName(e.target.value)}
                      />
                    )}
                  </>
                ) : (
                  <Box sx={{display: "flex", flexDirection: "column"}}>
      <TextField
        margin="normal"
        required
        fullWidth
        name="otp"
        label="OTP"
        type="number"
        value={otp}
        inputProps={{
          type: "text",
          inputMode: "numeric",
          pattern: "d*",
          min: 0,
          maxLength: 6,
        }}
        onChange={(e) => {
          if (!isNaN(e.target.value)) {
            setOTP(e.target.value);
          }
        }}
        autoFocus
        id="otp"
      />
      {showResendButton && (<Button
        type="button"
        fullWidth
        variant="text"
        onClick={() => handleResendOTP(phoneNumber, name, type)}
        sx={{ mt: 2 }}
      >
        Resend OTP
      </Button>)}
    
    </Box>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {isOTP ? "Verify OTP" : "Send OTP"}
                </Button>
                {error && (
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <InfoIcon />
                    <Typography variant="body2">{error}</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Box pt={2}>
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
}
