import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "@/components/LandingPage";
import ErrorPage from "@/routes/ErrorPage";
import PricingPage from "@/components/PricingTable";
import Login from "@/components/Login";
import RedirectPage from "@/components/RedirectingPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/pricing",
    element: <PricingPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/redirect",
    element: <RedirectPage />,
  },
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
